.button{
  max-width: 259px;
  width: 100%;
  border-radius: 28px;
  background-color: #023778;
  color: #FFFFFF;

  text-align: center;

  margin-bottom: 1.5vh;

  border: none;
  cursor: pointer;

  &:hover {
      background-color: #023778c7;
  }

  &:disabled{
    background-color: #B3B3B3;
    pointer-events: none;
  }

  &--transparent {


    background-color: transparent;
    color: #0366A4;

    &:hover {
      color: #0366A4c7;
      background-color: transparent;
    }
  }

  &__icon {
    max-width: 130px;
    line-height: 20px;

    .icon {
      float: right;
      display: inline;
      margin: 2.5px 5px;
      width: 15px;
      height: 15px;
    }
  }

  &--short {
    height: 30px;
    font-size: 15px;
    line-height: 18px;
  }

  &--long {
    height: 46px;
    font-size: 17px;
    line-height: 20px;

  }

  &--red{
    background-color: #780202;
  }
}
