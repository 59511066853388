.Rocket {
  .WinnerPage {
    &__sub-title {
      font-size: 20px;
      line-height: 22px;

      small {
        font-size: 12px;
        line-height: 16px;
      }

      p {
        margin: 0;
      }
    }

    &__prize {
      margin: 0;
      max-width: 300px;
    }
  }
}
