.Client {
  .HomePage {
    &__logo {
      max-width: 155px;
      width: 100%;
      margin-bottom: 50px;

      @media screen and (max-width:320px) {
        max-width: 144px;
      }
    }

    &__buttons {

      z-index: 1;
      width: 100%;

      @media screen and (max-width:320px) {
        max-width: 230px;

        &.button {
          font-size: 17px;
          line-height: 20px;
        }
      }
    }

    &__decoration {
      @extend .Page__bg,
              .Page__bg--bottom,
              .Page__bg--left;

      max-width: 35vw;
      width: 140px;
    }
  }
}
