@font-face {
  font-family: "bell_slim_heavy";
  src: url("./../fonts/bellslimheavy-webfont.woff2") format("woff2"),
    url("./../fonts/bellslimheavy-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bell_slim_medium";
  src: url("./../fonts/bellslimmedium-webfont.woff2") format("woff2"),
    url("./../fonts/bellslimmedium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.font-bell-heavy {
  font-family: "bell_slim_heavy";
  letter-spacing: 1px;
}

.font-bell-medium {
  font-family: "bell_slim_medium";
  letter-spacing: 0;
}
