.Jumbotron {
  .SignupPage {
    &__content {
      @extend .full-window, .flex, .flex--row;
      width: 100%;
      flex: 0 1;
    }

    &__decoration {
      @extend .Page__bg, .Page__bg--bottom, .Page__bg--left;
    }

    &__column {
      @extend .flex, .flex--center, .flex--middle, .flex--column;

      flex: 0 1 45%;
    }

    &__logo {
      width: 405px;
      margin-bottom: 70px;
    }

    &__qr {
      @extend .flex, .flex--center, .flex--middle, .flex--column;

      width: 980px;
      height: 980px;
      border: 1px solid #ffffff;
      border-radius: 37px;
      background-color: #ffffff;
      box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.2);
    }

    &__instructions {
      @extend .font-bell-heavy;

      text-align: center;
      font-size: 75px;
      line-height: 81px;
      font-weight: 500;

      margin-bottom: 50px !important;

      p {
        margin: 0;
      }
    }
  }
}
