.Jumbotron {
  .HomePage {
    &__logo{
      max-width: 424px;
      width: 100%;
      height: auto;
    }

    &__instructions {
      @extend .font-bell-medium;

      font-size: 90px;
      font-weight: 700;
      line-height: 97px;
      text-align: center;
    }

    &__decoration {
      @extend .Page__bg,
              .Page__bg--top,
              .Page__bg--left;
    }
  }
}
