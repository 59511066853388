.Client {
  .WaitingPage {
    &__title {
      @extend .title,
              .title--blue;
    }

    &__text {
      @extend .text;
    }

    &__image {
      margin-top: 50px;
      width: 100%;
    }
  }
}
