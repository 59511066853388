.Client {
  .SignupPage {

    &__logo {
      width: 100%;
      max-width: 154px;

      @media screen and (max-width:320px) {
        max-width: 104px;
      }
    }

    &__text {
      @extend .text;

      padding-bottom: 15px;
    }

    &__rules-link{
      @extend .button,
              .button--transparent;
    }

    &__decoration-image {
      @extend .Page__bg,
              .Page__bg--top,
              .Page__bg--left;

      max-width: 35vw;
      width: 140px;
    }
  }
}
