// teams colors
.Stampede {
  .WinnerPage__title {
    font-size: 30px;
    line-height: 31px;
  }

  .WinnerPage__sub-title {
    font-size: 24px;
    line-height: 29px;
  }

  .LoserPage__title {
    font-size: 30px;
    line-height: 31px;
  }

  .LoserPage__sub-title {
    font-size: 24px;
    line-height: 29px;
  }


}
