.Client {
  .CountdownPage {
    &__title {
      @extend .title;
    }

    &__number {
      @extend .font-bell-heavy;

      color: #0366A4;
      font-size: 300px;
      line-height: 322px;
    }
  }
}
