@import "./fonts.scss";
@import "./buttons.scss";
@import "./helpers.scss";
@import "./page.scss";
@import "./modal.scss";
@import "./toolbar.scss";
@import "./admin/admin.scss";
@import "./client/client.scss";
@import "./jumbotron/jumbotron.scss";

:root {
  color-scheme: only light;
  --lightshow-color: #0366a4;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: Helvetica;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #FCFCFC;
  color: #000;

  @media (prefers-color-scheme: dark) {
    background-color: #FCFCFC;
    color: #000;
  }
}

// reset styles
* {
  box-sizing: border-box;
}

// loading icon
.loading {
  max-height: 100%;
}
