.Modal {
  @extend .full-window,
          .flex,
          .flex--center,
          .flex--column;

  z-index: 10;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  background: #fff;
  overflow-y: scroll;

  &__title {
    @extend .font-bell-heavy;

    color: #000000;
    font-size: 32px;
    line-height: 34px;
    text-align: center;
    width: 300px;
    margin: 0;
    margin-top: 20px;
  }

  &__close-button {
    color: #aaaaaa;
    position: fixed;
    top: 10px;
    right: 20px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }

  &__content {
    height: auto;
    color: #000000;
    font-family: Helvetica;
    font-size: 21px;
    line-height: 25px;
    text-align: center;
    padding: 2vw;
    max-width: 300px;

    @media screen and (max-width:320px) {
      max-width: 250px;
    }

    &-buttons {
      @extend .flex,
              .flex--center,
              .flex--column;
    }
  }
}
