@import "./home.scss";
@import "./signup.scss";
@import "./countdown.scss";
@import "./endscreen.scss";
@import "./lightshow.scss";

.Jumbotron {
  height: 1080px;
  width: 1920px;

  .Presentation {
    &__video {
      @extend .full-window;

      background-color: #fff;
    }
  }

  .Page__content {
    height: 1080px;
    width: 1920px;
  }

  &__connection-indicator {
    @extend .Page__bg, .Page__bg--right, .Page__bg--bottom;

    margin: 10px;
  }
}
