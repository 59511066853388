.Jumbotron {
  .EndScreen {
    &__bg {
      @extend .Page__bg, .Page__bg--top, .Page__bg--left;
    }

    &__logo {
      max-width: 424px;
      width: 100%;
      height: auto;
    }

    &__content {
      @extend .font-bell-heavy;

      font-size: 90px;
      line-height: 97px;
      text-align: center;

      .blue {
        color: #0068a9;
      }

      p {
        margin: 50px 0;
      }
    }
  }
}
