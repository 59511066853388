.Client {
  &.BlackscreenPage {
    background-color: #000000;
  }

  .BlackscreenPage {
    &__text{
      @extend .title;

      color: #fff;
    }
  }
}
