.Raptors {
  .WinnerPage {
    &__sub-title {
      font-size: 24px;
      line-height: 26px;

      p {
        margin: 0;
      }
    }

    &__instructions {
      font-size: 19px;
      line-height: 21px;
    }

    &__prize {
      max-width: 300px;
    }
  }
}
