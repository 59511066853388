.Client {
  .LoserPage {
    &__title {
      @extend .title,
              .title--blue;
    }

    &__sub-title {
      @extend .sub-title;

      padding-bottom: 30px;
    }

    &__text {
      @extend .text,
              .text--bold;
      margin-bottom: 15px;
    }

    &__image {
      max-width: 400px;
      width: 100vw;
      margin-bottom: 35px;
    }
  }
}
