.Client {
  .SorryPage {
    &__title {
      @extend .title,
              .title--blue;

      // margin-bottom: 2vh;
      // width: 300px;

    }

    &__sub-title {
      @extend .sub-title;

      padding-bottom: 30px;
    }
  }
}
