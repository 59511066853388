@import "./countdown.scss";
@import "./home.scss";
@import "./signup.scss";
@import "./loser.scss";
@import "./waiting.scss";
@import "./winner.scss";
@import "./sorry.scss";
@import "./blackscreen.scss";
@import "./teams/teams.scss";

.Client {
  &.LightshowPage {
    @extend .lightshow;
  }

  &.WinnerslightPage {
    @extend .lightshow;

    animation-duration: 0.3s;
  }

  .Page__content {
    margin: 0 auto;
    min-height: 100%;
    max-width: 320px;
    padding: 40px 30px;
  }
}
