.Client {
  .WinnerPage {
    &__title {
      @extend .title,
              .title--blue;

      margin-bottom: 20px;
      font-size: 31px;
      line-height: 33px;
    }

    &__sub-title {
      @extend .title;

      font-size: 28px;
      line-height: 30px;
    }


    &__instructions {
      @extend .title;

      font-size: 24px;
      line-height: 26px;

      small{
        font-size: 16px;
      }
    }

    &__prize {
      max-width: 400px;
      width: 100vw;
      margin-bottom: 35px;
    }

    &-raptors {
      &__logo {
        margin: 25px 0;
        width: 300px;
        max-width: 70vw;
      }
    }
  }
}
