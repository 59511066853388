.Jumbotron {
  .Lightshow {
    @extend .full-window;

    &__player {
      @extend .full-window;

      top: -10% !important;
      background-color: #fff;
      width: 100vw;
      height: 100vh;
    }

    &__bar {
      @extend .flex;

      background-color: #000;
      bottom: 0;
      color: #fff;
      left: 0;
      position: fixed;
      width: 100vw;

      &-text {
        @extend .font-bell-heavy;

        flex: 1;
        font-size: 80px;
        line-height: 86px;
        padding: 45px 70px;

        p{
          margin: 0;
        }
      }

      &-number {
        @extend .font-bell-heavy;

        flex: 0 90px;
        font-size: 150px;
        line-height: 161px;
        padding: 55px 53px 45px 0px;
      }
    }
  }
}
