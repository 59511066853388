.Admin {
  &__header {
    background-color: #4f4f4f;
    color: #fff;
    width: 100vw;
    padding: 10px 0;
    font-size: 25px;
    text-align: center;

    .Indicator {
      display: inline-block;
    }
  }

  &__confirm {
    position: fixed;
    min-width: 100vw;
    background-color: #eee;
  }

  &__login {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 70px;

    &-title {
      color: #0068a9;
      font-family: "bellslim_heavyregular";
      font-size: 40px;
      letter-spacing: 0;
      line-height: 43px;
      text-align: center;
      font-weight: 500;
    }

    &-password {
      box-sizing: border-box;
      height: 46px;
      width: 259px;
      border: 1px solid #023778;
      border-radius: 28px;
      background-color: #ffffff;
      color: #828282;
      font-family: Helvetica;
      font-size: 17px;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      outline: none;
      margin-bottom: 16px;

      &:focus::-webkit-input-placeholder {
        opacity: 0;
      }
    }
  }
}
