.Jumbotron {
  .CountdownPage {
    &__title {
      @extend .font-bell-heavy;

      font-size: 80px;
      line-height: 86px;
      font-weight: 500;
      text-align: center;
      margin: 40px 0 20px;
      width: 1020px;

      p {
        margin: 0;
      }
    }
    &__content {
      @extend .flex, .flex--row;

      width: 90%;
      height: 850px;
      justify-content: space-evenly;
    }

    &__column {
      @extend .flex, .flex--center, .flex--middle, .flex--column;

      flex: 0 1 30%;
    }

    &__image {
      &-phone {
        height: 580px;
        margin-bottom: 30px;
      }

      &-arrow {
        width: 280px;
      }
    }

    &__number {
      @extend .font-bell-heavy;

      color: #0366a4;
      font-size: 600px;
      line-height: 645px;
      padding: 10px;
    }

    &__decoration {
      @extend .Page__bg, .Page__bg--right, .Page__bg--bottom;
    }
  }
}
