
.flex{
  display: flex;

  &--center {
    align-items: center;
  }

  &--middle{
    justify-content: center;
  }

  &--column {
    flex-flow: column;
  }

  &--row {
    flex-flow: row;
  }
}

// Screen overlay helpers
.full-window {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100vw;
  max-height: 100vh;

  &--overlay{
    background-color: rgba(190,190,190,0.07);
    pointer-events: none;
  }
}

// Lightshow helpers
@keyframes blinker {
  from {
    opacity: 1.0;
  }

  to {
    opacity: 0.0;
  }
}

.lightshow {
  @extend .full-window;

  // background-color: #0366A4; // blue
  // background-color: #b9975b; // gold
  background-color: var(--lightshow-color);
  animation: blinker;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}


// TO REVIEW: High hack to fill screen hight
.full-height {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  pointer-events: none;

  @media screen and (orientation: landscape){
    height: 700px;
  }
}


// title

.title{
  @extend .font-bell-heavy;

  user-select: none;

  font-size: 32px;
  font-weight: 500;

  line-height: 34px;
  text-align: center;

  margin-bottom: 3vh;

  @media screen and (max-width:320px) {
    font-size: 22px;
    line-height: 24px;
  }

  &--blue {
    color: #0068a7;
  }
}

.sub-title {
  @extend .font-bell-medium;

  user-select: none;

  font-size: 31px;
  font-weight: 400;
  line-height: 33px;
  text-align: center;

  @media screen and (max-width:320px) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 32px;
  }
}

.text {
  user-select: none;

  font-size: 21px;
  line-height: 25px;
  text-align: center;

  @media screen and (max-width:320px) {
    font-size: 16px;
    line-height: 20px;
  }

  &--bold {
    font-weight: bold;
  }
}
