.Toolbar{
  @extend .flex,
          .flex--column;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
  background-color: rgba(0,0,0,0.5);

  &__actions {
    @extend .flex;
    z-index: 10;
    width: 100vw;
    padding: 5px;

    &-id {
      flex: 1 1;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      text-align: center;
    }

    &-indicator {
      flex: 0 20px;
      border-radius: 50%;
      background-color: #efefef;
      width: 20px;
      height: 20px;
      cursor: pointer;

      &--enabled {
        background-color: #4bbf73;
      }

      &--disabled {
        background-color: #d9534f;
      }

    }

    &-icon {
      flex: 0 20px;
      width: 20px;
      height: 20px;
      font-size: 20px;
      line-height: 22px;
      font-weight: bold;
      cursor: pointer;

      &--enabled {
        color: #4bbf73;
      }

      &--disabled {
        color: #d9534f;
      }

    }
  }

  &__logs {
    border-top: 1px #efefef dashed;

    bottom: 40px;
    height: 30%;
    max-height: 30vh;
    overflow-y: auto;
    & > div {
      flex-flow: column-reverse;
      display: flex;
    }
  }
}


.Indicator{
  margin: 0 5px;
  width: 20px;
  height: 20px;
  font-size: 20px;
  line-height: 22px;
  font-weight: bold;

  cursor: pointer;

    &--enabled {
        color: #4bbf73;
      }

      &--disabled {
        color: #d9534f;
      }

}
